import { api, returningError } from 'src/api';
import { store } from 'src/redux';
export interface StripeSaleI {
   sale: {
      date: string;
      promo_code?: string;
      items: Array<{
         product_id: number;
         quantity: number;
      }>;
      payment_type: 'card';
      payment_method: string;
      percent: number;
      currency: string;
   };
   locale: string;
   customer: {
      name: string;
      lastnames: string;
      email: string;
      phone: string;
   };
   zone_id: number;
}

export const postStripeSale = async (body: StripeSaleI) => {
   try {
      const { data }: any = await api.post(`/${store.getState().app.locale || 'es'}/v1/sales_intent`, body);
      return { ...data, success: 'true' };
   } catch (error) {
      return returningError(error);
   }
};

export interface ConfirmSaleI {
   sale: {
      date: any;
      payment_type: string;
      payment_intent_id: string;
      percent: string | number;
      promo_code: string | undefined;
      items: any[];
      currency: string;
   };
   // user service info
   customer: {
      lastnames: string | undefined;
      name: string;
      phone: string;
      email: string;
   };
   // address info
   address: {
      latitude: string;
      longitude: string;
      alias: string;
      details: string;
      zipcode: string;
      zone_id: number | string;
      administrative_area_level_1: string;
      locality: string;
      sublocality: string;
      neighborhood: string;
      number: string;
      street: string;
   };
}

export const postStripeConfirmSale = async (body: ConfirmSaleI) => {
   try {
      const { data }: any = await api.post(`/${store.getState().app.locale || 'es'}/v1/confirm_stripe`, body);
      return { ...data, success: 'true' };
   } catch (error: any) {
      console.log(error.request);
      return returningError(error);
   }
};
