import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Tooltip } from 'antd';

import { Link, useNavigate } from 'react-router-dom';

import { glookLogo } from 'src/assets/images';
import { SearchAutocomplete, Select } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import SelectLanguage from 'src/pages/auth/SelectLanguage';
import { getOption } from 'src/pages/compositions/getCommonComponent';
import { AppI, CommonI, setLocale, setCurrentService, setLang, setCurrentZone, CityI } from 'src/redux';

import layoutI18n from 'src/i18n/layout.i18n.json';
import homeI18n from 'src/i18n/home.i18n.json';
import styles from './DrawerApp.module.css';

interface DrawerAppI {
   onCloseDrawer?: () => void;
   openDrawer: boolean;
}

const DrawerApp = ({ openDrawer, onCloseDrawer }: DrawerAppI) => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { locale, lang } = useAppSelector(({ app }: { app: AppI }) => app);
   const { cities: citiesZ, services, currentService, currentZone } = useAppSelector(({ common }: { common: CommonI }) => common);

   const [cities, setCities] = useState<Array<CityI>>([]);
   // const [city, setCity] = useState('00');
   const [service, setService] = useState('00');

   useEffect(() => {
      if (currentService) {
         setService(currentService);
         dispatch(setCurrentService(currentService));
      } else {
         setService('00');
         dispatch(setCurrentService(''));
      }
   }, [currentService]);

   useEffect(() => {
      if (citiesZ.length > 0) {
         const arr = citiesZ.filter((i) => i.country_id === 2);
         setCities(arr);
      }
   }, [locale]);

   // useEffect(() => {
   //     if (currentCity) {
   //         setCity(currentCity);
   //         dispatch(setCurrentCity(currentCity));
   //     }
   // }, [])

   return (
      <Drawer
         onClose={onCloseDrawer}
         open={openDrawer}
         title={
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
               <div style={{ width: '80%', textAlign: 'center' }}>
                  <img src={glookLogo} alt='glook-logo' width={160} height={30} />
               </div>
               <div style={{ width: '20%', textAlign: 'right' }}>
                  <Button
                     style={{ padding: 0 }}
                     type='link'
                     onClick={() => {
                        navigate(-1);
                        if (onCloseDrawer) onCloseDrawer();
                     }}>
                     <ArrowLeftOutlined style={{ fontSize: 20, color: '#C2A799' }} />
                  </Button>
               </div>
            </div>
         }
         placement='left'>
         <Link to='/nosotros' onClick={() => setTimeout(() => onCloseDrawer?.(), 500)}>
            {layoutI18n[lang].about}
         </Link>
         <Divider />
         <h5>{layoutI18n[lang].countrylangText}</h5>
         <Tooltip
            placement='bottom'
            color='#FFFFFF'
            title={
               <SelectLanguage
                  locale={locale}
                  lang={lang}
                  onChangeLocale={(value) => dispatch(setLocale(value))}
                  onChangeLang={(value) => dispatch(setLang(value))}
               />
            }>
            <div className={styles.select_locale}>{getOption(locale, lang, styles.flags)}</div>
         </Tooltip>
         <h5>{layoutI18n[lang].citiesText}</h5>
         {locale === 'es' && (
            <SearchAutocomplete
               styleContainer={{
                  marginBottom: 24,
               }}
               styleInput={{
                  width: '100%',
               }}
               styleBoxPredictions={{
                  backgroundColor: '#FFF',
                  zIndex: 10,
                  width: 300,
                  borderRadius: 10,
               }}
               placeholder={homeI18n[lang].selectPlaceholderCity}
               size='large'
               locale={locale}
            />
         )}
         {locale === 'en' && (
            <SearchAutocomplete
               styleContainer={{
                  marginBottom: 24,
               }}
               styleInput={{
                  width: '100%',
               }}
               styleBoxPredictions={{
                  backgroundColor: '#FFF',
                  zIndex: 10,
                  width: 300,
                  borderRadius: 10,
               }}
               placeholder={homeI18n[lang].selectPlaceholderCity}
               size='large'
               locale={locale}
            />
         )}
         {locale === 'es-ES' && (
            <SearchAutocomplete
               styleContainer={{
                  marginBottom: 24,
               }}
               styleInput={{
                  width: '100%',
               }}
               styleBoxPredictions={{
                  backgroundColor: '#FFF',
                  zIndex: 10,
                  width: 300,
                  borderRadius: 10,
               }}
               placeholder={homeI18n[lang].selectPlaceholderCity}
               size='large'
               locale={locale}
            />
         )}
         {currentZone.locality && (
            <>
               <h5>{layoutI18n[lang].services}</h5>
               <Select
                  size='large'
                  placeholder={homeI18n[lang].selectPlaceholderServices}
                  style={{ width: '100%' }}
                  options={
                     services.length > 0
                        ? [
                             { value: '00', label: homeI18n[lang].selectChooseService },
                             ...services.map((i: any) => ({ value: `${i.id}`, label: i.name[lang] || 'Sin Nombre' })),
                          ]
                        : [{ value: '00', label: homeI18n[lang].selectNoResult }]
                  }
                  onChange={(value) => {
                     setService(value);
                     dispatch(setCurrentService(value !== '00' ? value : ''));
                     if (value !== '00') navigate(`/servicios/${value}`);
                  }}
                  value={service}
               />
            </>
         )}
      </Drawer>
   );
};

export default DrawerApp;
