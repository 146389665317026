import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Modal, Radio, Row, notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/en';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { glLettersLogo } from 'src/assets/images';
import { Input } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AppI, CartI, CartProductI, CommonI, deleteProdCart, setDiscountCode, setProdCart, setUserInfo } from 'src/redux';

import styles from './ShoppingInfo.module.css';
import CheckoutForm from './CheckoutForm';
import { validatePromoCode } from 'src/api';
import ShoppingForm from './ShoppingForm';
import { getAmountByMonth, getAmountDiscount, getTotalDeferred } from 'src/utils';

import bagI18n from 'src/i18n/bag.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';

const KEY = process.env.REACT_APP_EPIRTS_KEY;
// Publishable key stripe
const stripePromise = loadStripe(KEY as string);

export interface SaleInfoI {
   date: string;
   payment_type: 'card';
   promo_code?: string;
   items: Array<{
      product_id: number;
      quantity: number;
   }>;
}

const AMOUNT_MINIMAL_MX = 800;
const AMOUNT_MINIMAL_EU = 45;
const AMOUNT_MINIMAL_ES = 5;

const getMinimalAmountByLocale = (locale: 'es' | 'en' | 'es-ES') => {
   switch (locale) {
      case 'en':
         return AMOUNT_MINIMAL_EU;
      case 'es-ES':
         return AMOUNT_MINIMAL_ES;
      default:
         return AMOUNT_MINIMAL_MX;
   }
};

const ShoppingInfo = () => {
   const { total, products, discountCode, user } = useAppSelector(({ cart }: { cart: CartI }) => cart.data);
   const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
   const { currentZone } = useAppSelector(({ common }: { common: CommonI }) => common);
   const dispatch = useAppDispatch();

   const formatter =
      locale === 'es-ES'
         ? new Intl.NumberFormat('sfb', {
              style: 'currency',
              currency: 'EUR',
           })
         : new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'MXN',
           });

   const minimalAmount = getMinimalAmountByLocale(locale);

   dayjs.locale(lang === 'es' ? 'es-mx' : 'en');

   const [showModal, setShowModal] = useState(false);
   const [payForm, setPayForm] = useState(1);
   const [width, setWidth] = useState(window.innerWidth);
   const [code, setCode] = useState('');
   const [discount, setDiscount] = useState<undefined | number>(undefined);
   const [sale, setSale] = useState<SaleInfoI>({
      date: '',
      payment_type: 'card',
      promo_code: discountCode,
      items: [],
   });

   const handleCheckout = async () => {
      try {
         const body: SaleInfoI = {
            date: `${dayjs(user.date).format('YYYY-MM-DD')} ${user.hour}`,
            payment_type: 'card',
            promo_code: discountCode,
            items: products.map((i) => ({ product_id: Number(i.id), quantity: i.numberProducts })),
         };
         setSale(body);
         setShowModal(true);
      } catch (error) {
         console.log(error);
      }
   };

   const handleValidateCode = async () => {
      try {
         const { data, success } = await validatePromoCode(code);
         if (success === 'true') {
            if (user.date === '') {
               setDiscount(undefined);
               dispatch(setDiscountCode(''));
               notification.warning({
                  message: notifI18n[lang].dateCodeInvalidTitleNotif,
                  description: notifI18n[lang].dateCodeInvalidDescriptionNotif,
               });
            } else {
               const currentDate = dayjs(user.date).format('YYYY/MM/DD');
               const startDate = dayjs(data.starts_at).format('YYYY/MM/DD');
               const expireDate = dayjs(data.expires_at).format('YYYY/MM/DD');
               if (
                  user.date &&
                  (dayjs(currentDate).isSame(startDate) || dayjs(currentDate).isAfter(startDate)) &&
                  (dayjs(currentDate).isSame(expireDate) || dayjs(currentDate).isBefore(expireDate))
               ) {
                  setDiscount(data.discount);
                  dispatch(setDiscountCode(code));
               } else {
                  setDiscount(undefined);
                  dispatch(setDiscountCode(''));
                  notification.warning({
                     message: notifI18n[lang].codeTitleNotif,
                     description: notifI18n[lang].codeDescriptionNotif,
                  });
               }
            }
         } else {
            setDiscount(undefined);
            dispatch(setDiscountCode(''));
            notification.warning({
               message: notifI18n[lang].codeTitleNotif,
               description: notifI18n[lang].codeDescriptionNotif,
            });
         }
      } catch (error: any) {
         dispatch(setDiscountCode(''));
         setDiscount(undefined);
         notification.error({
            message: 'Error',
            description: `${notifI18n[lang].commonDescriptionError} ${error.message}`,
         });
      }
   };

   const handlePays = (monthNumber: number) => {
      setPayForm(monthNumber);
   };

   useEffect(() => {
      window.addEventListener('resize', () => {
         setWidth(window.innerWidth);
      });
      return () => window.removeEventListener('resize', () => {});
   }, []);

   useEffect(() => {
      if (currentZone.locality) {
         dispatch(
            setUserInfo({
               ...user,
               address: currentZone.locality,
            })
         );
      }
   }, []);

   useEffect(() => {
      setPayForm(1);
   }, [discount]);

   return (
      <div className={styles.cart_detail}>
         <div className={styles.absolute_div} />
         <div className={`container pt-5 text-center ${styles.relative_div}`}>
            <h2>{bagI18n[lang].yourBagText}</h2>
            <Row style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
               <Col
                  className={styles.cart_contact_col}
                  xs={{ order: 2, span: 24 }}
                  sm={{ order: 2, span: 24 }}
                  md={{ order: 2, span: 24 }}
                  lg={{ order: 1, span: 12 }}
                  xl={{ order: 1, span: 12 }}
                  xxl={{ order: 1, span: 12 }}>
                  <div className={styles.cart_logo}>
                     <img src={glLettersLogo} width={70} height={70} alt='gl-logo' />
                  </div>
                  <ShoppingForm
                     address={user.address}
                     onSubmited={handleCheckout}
                     minimalAmount={
                        payForm === 1
                           ? discount
                              ? total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0) >= minimalAmount
                              : total + Number(currentZone.travelers) >= minimalAmount
                           : discount
                           ? getTotalDeferred(payForm, total + Number(currentZone.travelers)) - getAmountDiscount(total, discount || 0) >=
                             minimalAmount
                           : getTotalDeferred(payForm, total + Number(currentZone.travelers)) >= minimalAmount
                     }
                  />
               </Col>
               <Col
                  className={styles.cart_products_col}
                  style={{ backgroundColor: '#ECECEC' }}
                  xs={{ order: 1, span: 24 }}
                  sm={{ order: 1, span: 24 }}
                  md={{ order: 1, span: 24 }}
                  lg={{ order: 2, span: 12 }}
                  xl={{ order: 2, span: 12 }}
                  xxl={{ order: 2, span: 12 }}>
                  {products.length > 0 && (
                     <>
                        {products.map((i: CartProductI, index: number) => (
                           <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                              <div style={{ width: '20%' }}>
                                 <img style={{ borderRadius: 5, objectFit: 'cover' }} width={70} height={70} alt='service-item' src={i.uri} />
                              </div>
                              <div style={{ width: '35%', paddingLeft: 5, display: 'flex', justifyContent: 'center' }}>
                                 <h4 style={{ margin: 0 }}>{i.nameProduct[lang]}</h4>
                              </div>
                              <div style={{ width: '25%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                 <div style={{ border: '1px solid #D4D4D4', padding: '5px 0' }}>
                                    <Button
                                       style={{ width: 25, color: '#000' }}
                                       size='small'
                                       type='link'
                                       onClick={() => {
                                          dispatch(
                                             deleteProdCart({
                                                id: i.id,
                                             })
                                          );
                                       }}>
                                       -
                                    </Button>
                                    <Button size='small' value='default' disabled type='link'>
                                       {i.numberProducts}
                                    </Button>
                                    <Button
                                       style={{ width: 25, color: '#000' }}
                                       size='small'
                                       type='link'
                                       onClick={() => {
                                          dispatch(
                                             setProdCart({
                                                id: i.id,
                                                nameProduct: i.nameProduct,
                                                numberProducts: 1,
                                                cost: i.cost,
                                                uri: i.uri,
                                             })
                                          );
                                       }}>
                                       +
                                    </Button>
                                 </div>
                              </div>
                              <div style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                                 <h4 style={{ margin: 0 }}>{formatter.format(i.cost).replace('MX', '')}</h4>
                              </div>
                           </div>
                        ))}
                        <br />
                        <hr />
                     </>
                  )}
                  <>
                     <br />
                     <div
                        className={styles.shopping_radio_div}
                        style={{ display: 'flex', width: '100%', marginTop: 3 }}
                        onClick={() => {
                           handlePays(1);
                        }}>
                        <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>
                           <h3>{bagI18n[lang].travelExpenseText}</h3>
                        </div>
                        <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                           {formatter.format(Number(currentZone.travelers)).replace('MX', '')}
                        </div>
                     </div>
                  </>
                  <hr />
                  <br />
                  <div
                     className={styles.shopping_radio_div}
                     style={{ display: 'flex', width: '100%', marginTop: 3 }}
                     onClick={() => {
                        handlePays(1);
                     }}>
                     <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>
                        <h3>{bagI18n[lang].legendToBuy}</h3>
                     </div>
                     <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                        {formatter.format(minimalAmount).replace('MX', '')}
                     </div>
                  </div>
                  <hr />
                  <br />
                  <h3>{bagI18n[lang].discountCode}</h3>
                  <Row gutter={[4, 4]}>
                     <Col xs={18} sm={18} md={20} lg={18} xl={20} xxl={20}>
                        <Input
                           size='large'
                           placeholder={bagI18n[lang].discountPlaceholder}
                           value={code}
                           onChange={(e) => setCode(e.target.value ? e.target.value.toUpperCase().trim() : '')}
                           maxLength={15}
                        />
                     </Col>
                     <Col xs={6} sm={6} md={4} lg={6} xl={4} xxl={4}>
                        <Button type='primary' ghost onClick={handleValidateCode}>
                           {bagI18n[lang].btnAdd}
                        </Button>
                     </Col>
                  </Row>
                  <br />
                  <hr />
                  <br />
                  <>
                     {discount && (
                        <>
                           <Row>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                 <p>{bagI18n[lang].discountText}</p>
                              </Col>
                              <Col style={{ textAlign: 'right' }} xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                 <h3>{discount} %</h3>
                              </Col>
                           </Row>
                           <hr />
                        </>
                     )}
                     <h2 style={{ marginTop: 15 }}>{bagI18n[lang].wayToPayText}</h2>
                     <h4 style={{ marginTop: 20 }}>{bagI18n[lang].creditDebitText}</h4>
                     <div style={{ display: 'flex', marginTop: 15, marginBottom: 20 }}>
                        <Radio
                           onChange={() => {
                              handlePays(1);
                           }}
                           checked={payForm === 1}
                        />
                        <div
                           className={styles.shopping_radio_div}
                           style={{ display: 'flex', width: '100%', marginTop: 3 }}
                           onClick={() => {
                              handlePays(1);
                           }}>
                           <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>{bagI18n[lang].singleExhibition}</div>
                           <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                              {discount
                                 ? formatter.format(total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)).replace('MX', '')
                                 : formatter.format(total + Number(currentZone.travelers)).replace('MX', '')}
                           </div>
                        </div>
                     </div>
                     {locale === 'es' && (
                        <>
                           <h4 style={{ marginTop: 15 }}>{bagI18n[lang].deferredPays}</h4>
                           {/* {total >= 300 && */}
                           <div style={{ display: 'flex', marginTop: 20 }}>
                              <Radio
                                 onChange={() => {
                                    handlePays(3);
                                 }}
                                 checked={payForm === 3}
                                 disabled={
                                    discount
                                       ? getTotalDeferred(payForm, total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)) <
                                         300
                                       : getTotalDeferred(payForm, total + Number(currentZone.travelers)) < 300
                                 }
                              />
                              <div
                                 className={styles.shopping_radio_div}
                                 style={{ display: 'flex', width: '100%', marginTop: 3 }}
                                 onClick={() => {
                                    if (discount) {
                                       if (
                                          getTotalDeferred(
                                             payForm,
                                             total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)
                                          ) >= 300
                                       )
                                          handlePays(3);
                                    } else {
                                       if (getTotalDeferred(payForm, total + Number(currentZone.travelers)) >= 300) handlePays(3);
                                    }
                                 }}>
                                 <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>3 {bagI18n[lang].paysOf}</div>
                                 <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                                    {discount
                                       ? formatter
                                            .format(
                                               (getTotalDeferred(3, total + Number(currentZone.travelers)) -
                                                  getAmountDiscount(total, discount || 0)) /
                                                  3
                                            )
                                            .replace('MX', '')
                                       : formatter.format(getAmountByMonth(total + Number(currentZone.travelers), 0.053, 3)).replace('MX', '')}
                                 </div>
                              </div>
                           </div>
                           {/* } */}
                           {/* {total >= 600 && <> */}
                           <Divider style={{ margin: '20px 0' }} />
                           <div style={{ display: 'flex' }}>
                              <Radio
                                 onChange={() => {
                                    handlePays(6);
                                 }}
                                 checked={payForm === 6}
                                 disabled={
                                    discount
                                       ? getTotalDeferred(payForm, total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)) <
                                         600
                                       : getTotalDeferred(payForm, total + Number(currentZone.travelers)) < 600
                                 }
                              />
                              <div
                                 className={styles.shopping_radio_div}
                                 style={{ display: 'flex', width: '100%', marginTop: 3 }}
                                 onClick={() => {
                                    if (discount) {
                                       if (
                                          getTotalDeferred(
                                             payForm,
                                             total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)
                                          ) >= 600
                                       )
                                          handlePays(6);
                                    } else {
                                       if (getTotalDeferred(payForm, total + Number(currentZone.travelers)) >= 600) handlePays(6);
                                    }
                                 }}>
                                 <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>6 {bagI18n[lang].paysOf}</div>
                                 <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                                    {discount
                                       ? formatter
                                            .format(
                                               (getTotalDeferred(6, total + Number(currentZone.travelers)) -
                                                  getAmountDiscount(total, discount || 0)) /
                                                  6
                                            )
                                            .replace('MX', '')
                                       : formatter.format(getAmountByMonth(total + Number(currentZone.travelers), 0.08, 6)).replace('MX', '')}
                                 </div>
                              </div>
                           </div>
                           {/* </>} */}
                           {/* {total >= 900 && <> */}
                           <Divider style={{ margin: '20px 0' }} />
                           <div style={{ display: 'flex', marginBottom: 20 }}>
                              <Radio
                                 onChange={() => {
                                    handlePays(9);
                                 }}
                                 checked={payForm === 9}
                                 disabled={
                                    discount
                                       ? getTotalDeferred(payForm, total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)) <
                                         900
                                       : getTotalDeferred(payForm, total + Number(currentZone.travelers)) < 900
                                 }
                              />
                              <div
                                 className={styles.shopping_radio_div}
                                 style={{ display: 'flex', width: '100%', marginTop: 3 }}
                                 onClick={() => {
                                    if (discount) {
                                       if (
                                          getTotalDeferred(
                                             payForm,
                                             total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)
                                          ) >= 900
                                       )
                                          handlePays(9);
                                    } else {
                                       if (getTotalDeferred(payForm, total + Number(currentZone.travelers)) >= 900) handlePays(9);
                                    }
                                 }}>
                                 <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>
                                    9 {bagI18n[lang].paysOf}
                                    <br />
                                    <span style={{ fontSize: 11 }}>({bagI18n[lang].paysOfLegend} 900MX)</span>
                                 </div>
                                 <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                                    {discount
                                       ? formatter
                                            .format(
                                               (getTotalDeferred(9, total + Number(currentZone.travelers)) -
                                                  getAmountDiscount(total, discount || 0)) /
                                                  9
                                            )
                                            .replace('MX', '')
                                       : formatter.format(getAmountByMonth(total + Number(currentZone.travelers), 0.11, 9)).replace('MX', '')}
                                 </div>
                              </div>
                           </div>
                           {/* </>} */}
                           {/* {total >= 1200 && <> */}
                           <Divider style={{ margin: '20px 0' }} />
                           <div style={{ display: 'flex', marginBottom: 20 }}>
                              <Radio
                                 onChange={() => {
                                    handlePays(12);
                                 }}
                                 checked={payForm === 12}
                                 disabled={
                                    discount
                                       ? getTotalDeferred(payForm, total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)) <
                                         1200
                                       : getTotalDeferred(payForm, total + Number(currentZone.travelers)) < 1200
                                 }
                              />
                              <div
                                 className={styles.shopping_radio_div}
                                 style={{ display: 'flex', width: '100%', marginTop: 3 }}
                                 onClick={() => {
                                    if (discount) {
                                       if (
                                          getTotalDeferred(
                                             payForm,
                                             total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)
                                          ) >= 1200
                                       )
                                          handlePays(12);
                                    } else {
                                       if (getTotalDeferred(payForm, total + Number(currentZone.travelers)) >= 1200) handlePays(12);
                                    }
                                 }}>
                                 <div style={{ minWidth: width >= 350 ? '60%' : '50%' }}>
                                    12 {bagI18n[lang].paysOf}
                                    <br />
                                    <span style={{ fontSize: 11 }}>({bagI18n[lang].paysOfLegend} 1200MX)</span>
                                 </div>
                                 <div style={{ minWidth: width >= 350 ? '40%' : '50%', textAlign: 'right' }}>
                                    {discount
                                       ? formatter
                                            .format(
                                               (getTotalDeferred(12, total + Number(currentZone.travelers)) -
                                                  getAmountDiscount(total, discount || 0)) /
                                                  12
                                            )
                                            .replace('MX', '')
                                       : formatter.format(getAmountByMonth(total + Number(currentZone.travelers), 0.14, 12)).replace('MX', '')}
                                 </div>
                              </div>
                           </div>
                           {/* </>} */}
                        </>
                     )}
                     <Divider style={{ margin: '20px 0' }} />
                  </>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <h2>Total: </h2>
                     </Col>
                     <Col style={{ textAlign: 'right' }} xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <h2>
                           {payForm === 1
                              ? discount
                                 ? formatter.format(total + Number(currentZone.travelers) - getAmountDiscount(total, discount || 0)).replace('MX', '')
                                 : formatter.format(total + Number(currentZone.travelers)).replace('MX', '')
                              : discount
                              ? formatter
                                   .format(getTotalDeferred(payForm, total + Number(currentZone.travelers)) - getAmountDiscount(total, discount || 0))
                                   .replace('MX', '')
                              : formatter.format(getTotalDeferred(payForm, total + Number(currentZone.travelers))).replace('MX', '')}
                        </h2>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </div>
         {showModal && (
            <Modal
               open={showModal}
               footer={null}
               centered
               title={bagI18n[lang].shopModalTitle}
               onCancel={() => {
                  setShowModal(false);
               }}>
               <Elements stripe={stripePromise}>
                  <CheckoutForm months={payForm} sale={sale} showModal={showModal} />
               </Elements>
            </Modal>
         )}
      </div>
   );
};
export default ShoppingInfo;
