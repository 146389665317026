import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { Input, notification } from 'antd';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { getInfoByPlaceId, getLocateYourZone } from 'src/api';
import { getProperties } from 'src/utils';
import { AppI, CommonI, setClearIntent, setCurrentZone, setResetCart } from 'src/redux';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import styles from './SearchAutocomplete.module.css';

import notifI18n from 'src/i18n/notifications.i18n.json';

interface SearchAutocompleteI {
   placeholder?: string;
   styleContainer?: CSSProperties;
   styleInput?: CSSProperties;
   styleBoxPredictions?: CSSProperties;
   locale?: 'es' | 'en' | 'es-ES';
   isRef?: boolean;
   onBlurRef?: () => void;
   size?: 'middle' | 'large' | 'small';
}

type GooglePlaceIdT = {
   place_id: string;
   description: string;
   reference: string;
   types: string[];
   structured_formatting: {
      main_text: string;
      secondary_text: string;
   };
   terms: Array<{
      offset: string | number;
      value: string;
   }>;
};

type AddressT = Array<{
   long_name: string;
   short_name: string;
   types: string[];
}>;

const getCountryByLocale = (locale: 'es' | 'es-ES' | 'en') => {
   switch (locale) {
      case 'es-ES':
         return 'es';
      case 'en':
         return 'us';
      default:
         return 'mx';
   }
};

const SearchAutocomplete = ({
   placeholder,
   styleBoxPredictions,
   styleContainer,
   styleInput,
   locale = 'es',
   isRef,
   onBlurRef,
   size = 'middle',
}: SearchAutocompleteI) => {
   const searchRef = useRef<any>();
   const dispatch = useAppDispatch();
   const { currentZone } = useAppSelector(({ common }: { common: CommonI }) => common);
   const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

   const [val, setVal] = useState(currentZone.locality ? currentZone.locality : '');
   const [pred, setPred] = useState<Array<any>>([]);
   const [optionSelected, setOptionSelected] = useState<undefined | GooglePlaceIdT>(undefined);
   const [focused, setFocused] = useState(false);
   const [address, setAddress] = useState<undefined | AddressT>(undefined);

   const options = {
      types: ['(regions)'],
      componentRestrictions: {
         country: getCountryByLocale(locale),
      },
   };

   const { placePredictions, getPlacePredictions } = useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      options: options,
   });
   // service to get address info from google map API Service bridge
   const handlePlaceIdInfo = async () => {
      try {
         const { result } = await getInfoByPlaceId(optionSelected ? optionSelected.place_id : '');
         if (result && result.address_components) {
            setAddress(result.address_components);
         } else {
            notification.warning({
               message: notifI18n[lang].cityTitleNotification,
               description: notifI18n[lang].cityDescriptionNotification,
            });
         }
      } catch (error: any) {
         notification.warning({
            message: notifI18n[lang].cityTitleNotification,
            description: `${notifI18n[lang].commonDescriptionError} ${error.message}`,
         });
      }
   };
   // locate your zone service
   const handleLocateYourZone = async () => {
      try {
         if (address) {
            const { data } = await getLocateYourZone({
               locality: getProperties(address, 'locality') || '',
               administrative_area_level_1: getProperties(address, 'administrative_area_level_1') || '',
               administrative_area_level_2: getProperties(address, 'administrative_area_level_2') || '',
               sublocality: getProperties(address, 'sublocality') || '',
               neighborhood: getProperties(address, 'neighborhood') || '',
            });

            if (data && Object.keys(data).length > 0) {
               dispatch(
                  setCurrentZone({
                     id: data.id || 0,
                     factor: data.factor || 1,
                     locality: data.locality ? `${data.locality ? `${data.locality}, ` : ''}${data.administrative_area_level_1 || ''}` : val,
                     travelers: data.travelers || 0,
                  })
               );
               dispatch(setResetCart());
               setFocused(false);
            } else {
               notification.info({
                  message: notifI18n[lang].cityTitleNotification,
                  description: notifI18n[lang].zoneDescriptionNotificationOne,
               });
            }
         } else {
            notification.info({
               message: notifI18n[lang].cityTitleNotification,
               description: notifI18n[lang].zoneDescriptionNotificationTwo,
            });
         }
      } catch (error: any) {
         notification.warning({
            message: notifI18n[lang].cityTitleNotification,
            description: `${notifI18n[lang].commonDescriptionError} ${error.message}`,
         });
      }
   };
   // clean state when locale is modified
   useEffect(() => {
      setVal('');
      setPred([]);
      setOptionSelected(undefined);
      setFocused(false);
   }, [locale]);
   // use effect to control input search behavior
   useEffect(() => {
      if (!val) {
         setPred([]);
      }

      if (val && optionSelected && optionSelected.description !== val) {
         setOptionSelected(undefined);
      }
   }, [val]);
   // set list of predictions of input search result
   useEffect(() => {
      if (val && placePredictions.length > 0 && !optionSelected) {
         setPred(placePredictions);
      }
   }, [val, placePredictions]);
   // get info of google bridge service
   useEffect(() => {
      if (optionSelected) {
         handlePlaceIdInfo();
      }
   }, [optionSelected]);
   // effect to get Zone Info
   useEffect(() => {
      if (address) {
         handleLocateYourZone();
      }
   }, [address]);

   useEffect(() => {
      if (!focused && !optionSelected && pred.length === 0 && currentZone.locality) {
         setVal(currentZone.locality);
      }
   }, [focused, optionSelected, pred, address]);

   useEffect(() => {
      if (isRef) searchRef?.current?.focus();
   }, [isRef]);

   useEffect(() => {
      setVal(currentZone.locality);
   }, [currentZone.locality]);

   return (
      <div style={styleContainer}>
         <div id='map' hidden />
         <Input
            ref={searchRef}
            style={styleInput}
            value={val}
            placeholder={placeholder}
            onChange={(evt) => {
               getPlacePredictions({ input: evt.target.value });
               setVal(evt.target.value);
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => {
               if (onBlurRef) onBlurRef();
               setTimeout(() => {
                  setFocused(false);
               }, 500);
            }}
            size={size}
         />
         {pred.length > 0 && focused && (
            <div
               style={
                  styleBoxPredictions
                     ? {
                          position: 'absolute',
                          zIndex: 10,
                          width: 300,
                          backgroundColor: '#FFF',
                          marginTop: 5,
                          borderRadius: 10,
                          ...styleBoxPredictions,
                       }
                     : {
                          position: 'absolute',
                          zIndex: 10,
                          width: 300,
                          backgroundColor: '#FFF',
                          marginTop: 5,
                          borderRadius: 10,
                          padding: 5,
                       }
               }>
               {pred.map((i, _) => (
                  <div
                     className={styles.google_option}
                     key={_}
                     onClick={() => {
                        setOptionSelected(i);
                        setVal(i.description);
                        setPred([]);
                     }}>
                     {i.description}
                  </div>
               ))}
            </div>
         )}
      </div>
   );
};

export default SearchAutocomplete;
