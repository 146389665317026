import { CartI, CommonI, UserDataI } from '.';

/*
 * Common Initial State
 */

export const initialStateProducts = {
   category_id: 0,
   cost_price: 0,
   description: { en: '', es: '' },
   duration: 0,
   id: 0,
   name: { en: '', es: '' },
   order: 0,
   photo: '',
   sell_price: 0,
   sell_price_usd: 0,
   sell_price_eur: 0,
   status: false,
   translated_name: '',
};

export const initialStateZone = {
   id: 0,
   factor: 1,
   locality: '',
   travelers: 0,
};

export const commonInitialState: CommonI = {
   cities: [],
   services: [],
   products: [],
   currentZone: initialStateZone,
   currentProduct: initialStateProducts,
   currentService: '',
   citiesIsFetching: false,
   citiesSuccess: false,
   productsIsFetching: false,
   productsSuccess: false,
   servicesIsFetching: false,
   servicesSuccess: false,
};

/*
    CART Initial State
*/
export const cartInitState: CartI = {
   data: {
      products: [],
      discountCode: '',
      subTotal: 0,
      total: 0,
      user: {
         name: '',
         surName: '',
         email: '',
         phone: '',
         address: '',
         zipCode: '',
         addressMunicipality: '',
         neighboorhood: '',
         addressStreet: '',
         addressExtNumber: '',
         addressIntNumber: '',
         alias: '',
         details: '',
         date: '',
         hour: '',
      },
   },
   cartIsFetching: false,
   cartSuccess: false,
};

/*
    USER Initial State
*/
export const userSchema = {
   birthdate: '',
   conekta_id: '',
   device_token: '',
   email: '',
   face_id: '',
   gender: '',
   id: '',
   lastnames: '',
   name: '',
   photo_uri: '',
   qualification: '',
   token: '',
};

export const userInitState: UserDataI = {
   data: userSchema,
   userIsFetching: false,
   userSuccess: false,
};
