import React, { useEffect, useState } from 'react';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, notification } from 'antd';

import { StripeSaleI, postStripeSale, postStripeConfirmSale } from 'src/api';
import { Input } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { AppI, CartI, CommonI } from 'src/redux';
import { emailValidation, ucFirst } from 'src/utils';

import CardSection from './CardSection';

import notifI18n from 'src/i18n/notifications.i18n.json';
import formI18n from 'src/i18n/form.i18n.json';

import styles from './ShoppingInfo.module.css';

const getPercent = (monthPlace: number) => {
   switch (monthPlace) {
      case 3:
         return 1.053;
      case 6:
         return 1.08;
      case 9:
         return 1.11;
      case 12:
         return 1.14;
      default:
         return 1;
   }
};
interface CheckFormI {
   months: number;
   sale: {
      date: string;
      payment_type: 'card';
      promo_code?: string;
      items: Array<{
         product_id: number;
         quantity: number;
      }>;
   };
   showModal?: boolean;
}

const getCurrencyByLocale = (locale: 'es' | 'en' | 'es-ES') => {
   switch (locale) {
      case 'en':
         return 'USD';
      case 'es-ES':
         return 'EUR';
      default:
         return 'MXN';
   }
};

const CheckoutForm = ({ months, sale, showModal }: CheckFormI) => {
   const stripe = useStripe();
   const elements = useElements();

   const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
   const { user } = useAppSelector(({ cart }: { cart: CartI }) => cart.data);
   const { currentZone } = useAppSelector(({ common }: { common: CommonI }) => common);

   const [names, setNames] = useState('');
   const [lastNames, setLastNames] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [address, setAddress] = useState({
      street: '',
      number: '',
      locality: '',
      sublocality: '',
      neighborhood: '',
      administrative_area_level_1: '',
   });
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState(true);
   const [phoneEmpty, setPhoneEmpty] = useState(false);
   const [cardIsComplete, setCardIsComplete] = useState(false);

   const cElement = elements?.getElement(CardElement);

   const handleSubmit = async (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      if (!stripe || !elements) {
         return;
      }

      const cardElement: any = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
         type: 'card',
         card: cardElement || cElement,
      });

      if (error) {
         console.log(['error'], error);
      } else {
         const requestBody: StripeSaleI = {
            sale: {
               ...sale,
               payment_method: paymentMethod.id,
               percent: getPercent(months),
               currency: getCurrencyByLocale(locale),
            },
            locale,
            // card info
            customer: {
               name: names,
               lastnames: lastNames,
               email: email,
               phone: phone.replace(/ /g, ''),
            },
            zone_id: currentZone.id,
         };
         try {
            const response = await postStripeSale(requestBody);
            if (
               response.id &&
               response.payment_method_options.card.installments.available_plans &&
               response.payment_method_options.card.installments.available_plans.length > 0
            ) {
               handlePay(response.id, response.payment_method_options.card.installments.available_plans);
            } else {
               if (months !== 1) {
                  notification.error({
                     message: notifI18n[lang].payCardTitleNotification,
                     description: notifI18n[lang].payCardDescriptionNotification,
                  });
                  setIsLoading(false);
               } else {
                  handlePay(response.id, []);
               }
            }
         } catch (error: any) {
            console.log(error.message);
            setIsLoading(false);
         }
      }
   };

   const handlePay = async (intentId: string, plans: any[]) => {
      try {
         const body = {
            sale: {
               date: sale.date,
               payment_type: 'card',
               payment_intent_id: intentId,
               percent: getPercent(months),
               promo_code: sale.promo_code,
               items: sale.items,
               currency: getCurrencyByLocale(locale),
            },
            // user service info
            customer: {
               lastnames: user.surName,
               name: user.name,
               phone: user.phone.replace(/ /g, ''),
               email: user.email,
            },
            // address info
            address: {
               latitude: '19.42847',
               longitude: '-99.12766',
               alias: user.alias,
               details: user.details,
               zipcode: user.zipCode,
               zone_id: currentZone.id,
               ...address,
            },
         };
         if (months === 1) {
            const { data, success } = await postStripeConfirmSale(body);
            if (success === 'true') {
               window.location.replace(`${window.location.origin}/success?payment_intent=${data.payment_id}`);
            } else {
               notification.error({
                  message: notifI18n[lang].payCardTitleNotification,
                  description: notifI18n[lang].commonDescriptionError,
               });
            }
         } else {
            const selectedPlan = plans.find((item) => item.count === months);

            if (selectedPlan) {
               const request = {
                  ...body,
                  sale: {
                     ...body.sale,
                     selected_plan: selectedPlan,
                  },
               };
               const { data, success } = await postStripeConfirmSale(request);
               if (success === 'true') {
                  window.location.replace(`${window.location.origin}/success?payment_intent=${data.payment_id}`);
               } else {
                  notification.error({
                     message: notifI18n[lang].payCardTitleNotification,
                     description: notifI18n[lang].commonDescriptionError,
                  });
               }
            } else {
               notification.error({
                  message: notifI18n[lang].payCardTitleNotification,
                  description: notifI18n[lang].commonDescriptionError,
               });
            }
         }
         setIsLoading(false);
      } catch (error: any) {
         setIsLoading(false);
         notification.error({
            message: notifI18n[lang].payCardTitleNotification,
            description: `${notifI18n[lang].commonDescriptionError} ${error.message}`,
         });
      }
   };

   const clearCard = () => {
      // ensure the Element is still mounted
      if (cElement) {
         cElement.clear();
         setCardIsComplete(false);
      }
   };

   useEffect(() => {
      if (user.address || user.addressStreet || user.addressExtNumber || user.addressIntNumber || user.addressMunicipality || user.neighboorhood) {
         setAddress({
            street: user.addressStreet || '',
            number: user.addressExtNumber || '',
            locality: user.address,
            sublocality: user.addressMunicipality,
            neighborhood: user.neighboorhood,
            administrative_area_level_1: user.address,
         });
      }
   }, [user.address, user.addressStreet, user.addressExtNumber, user.addressIntNumber, user.addressMunicipality, user.neighboorhood]);

   useEffect(() => {
      if (
         names &&
         lastNames &&
         email &&
         emailValidation(email) &&
         phone &&
         phone.replace(/ /g, '').length === 10 &&
         phone.indexOf('_') === -1 &&
         cardIsComplete
      ) {
         setIsDisabled(false);
      } else {
         setIsDisabled(true);
      }
   }, [names, lastNames, email, phone, cardIsComplete]);

   useEffect(() => {
      if (!showModal) {
         clearCard();
         setNames('');
         setLastNames('');
         setEmail('');
         setPhone('');
      }
   }, [showModal]);

   return (
      <div className='col-lg-12'>
         <form onSubmit={handleSubmit}>
            <div className='form-group'>
               <h4>{lang === 'es' ? 'Datos del Titular' : 'Owner Data'}</h4>
               <Row gutter={[10, 5]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                     <Input
                        className='StripeElement'
                        size='large'
                        type='name'
                        id='name'
                        placeholder={`${formI18n[lang].namesPlaceHolder}*`}
                        value={names}
                        onChange={(e) => setNames(e.target.value ? ucFirst(e.target.value) : '')}
                     />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                     <Input
                        className='StripeElement'
                        size='large'
                        id='lastname'
                        placeholder={`${formI18n[lang].lastNamesPlaceHolder}*`}
                        value={lastNames}
                        onChange={(e) => setLastNames(e.target.value ? ucFirst(e.target.value) : '')}
                     />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                     <Input
                        className='StripeElement'
                        size='large'
                        id='email'
                        type='email'
                        placeholder={`${formI18n[lang].emailPlaceholder}*`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value ? e.target.value.toLowerCase().trim() : '')}
                        typeColor={email ? (emailValidation(email) ? 'default' : 'error') : 'default'}
                        status={email ? (emailValidation(email) ? '' : 'error') : ''}
                        text={email ? (emailValidation(email) ? '' : `${formI18n[lang].invalidFormatText}`) : ''}
                     />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                     <Input
                        className='StripeElement'
                        size='large'
                        type='tel'
                        id='phone'
                        placeholder={`${formI18n[lang].numberPlaceHolcer}*`}
                        typeColor={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? 'default' : phone !== '' ? 'error' : 'default'}
                        status={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? '' : phone !== '' ? 'error' : ''}
                        text={phoneEmpty ? `${formI18n[lang].requiredText}` : ''}
                        onChange={(e) => {
                           const inputValue = e.target.value;

                           // clean not numeric characters
                           const cleanedValue = inputValue.replace(/\D/g, '');

                           // apply mask for phone number
                           let maskedValue = '';
                           for (let i = 0; i < cleanedValue.length; i++) {
                              if (i === 2 || i === 6) {
                                 maskedValue += ' ';
                              }
                              maskedValue += cleanedValue[i];
                           }
                           setPhone(maskedValue);
                           if (maskedValue === '' || maskedValue.replace(/ /g, '').length < 10) return setPhoneEmpty(true);
                           setPhoneEmpty(false);
                        }}
                        onBlur={() => {
                           if (phone === '' || phone.replace(/ /g, '').length < 10) return setPhoneEmpty(true);
                           setPhoneEmpty(false);
                        }}
                        value={phone}
                        maxLength={12}
                     />
                  </Col>
               </Row>
               <h4>{lang === 'es' ? 'Datos de Tarjeta' : 'Card Data'}</h4>
               <CardSection callbackComplete={(isComplete) => setCardIsComplete(isComplete)} />
            </div>
            <br />
            <Button
               size='large'
               htmlType='submit'
               className={styles.payment_button}
               disabled={isLoading || !stripe || !elements || isDisabled}
               onClick={isLoading || !stripe || isDisabled || !elements ? () => {} : handleSubmit}>
               <span id='button-text'>{isLoading ? <LoadingOutlined /> : 'Pagar'}</span>
            </Button>
         </form>
      </div>
   );
};

export default CheckoutForm;
